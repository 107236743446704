import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"
import Layout from "../components/layout"
import ComposedContent from "../components/ComposedContent"
import Dateline from "../components/DateLine"
import { Typography } from "@mui/material"
import CenterColumn from "../components/CenterColumn"

class BlogFullPageTemplate extends React.Component {
  render() {
    const blogPost = get(this.props, "data.contentfulBlogPost")
    const siteTitle = get(this.props, "data.site.siteMetadata.title")

    return (
      <Layout breadcrumbs={[{ url: "/blog", title: "Blog" }]}>
        <Helmet title={`${blogPost.title} | ${siteTitle}`} />
        <CenterColumn>
          <Typography variant="h2">{blogPost.title}</Typography>

          <Dateline date={blogPost.publishDate} format="MMMM Do, YYYY" />
        </CenterColumn>
        {blogPost.content && <ComposedContent components={blogPost.content} />}
      </Layout>
    )
  }
}

export default BlogFullPageTemplate

export const pageQuery = graphql`
  query BlogBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate
      content {
        __typename
        ...textComponent
        ...imageComponent
      }
    }
  }
`
